import * as React from "react"
import ResumeEntry from "../components/ResumeEntry"

import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"

const resume_elements = [
    {
        name: "Education",
        long_name: "Texas A&M University",
        title: "Education",
        start: "August 2017",
        end: "Present",
        description: [
            "Current: Master of Science in Computer Science - 3.66 / 4.0",
            "Graduated May 2021: Bachelor of Science in Computer Science, Summa Cum Laude, Business Minor, Cybersecurity Minor, Engineering Honors, Undergraduate Research Scholar, GPA 3.94/4.0",
        ],
        link: "/education",
    },
    {
        name: "SwIRL",
        long_name: "Southwest Innovation Research Lab (SwIRL)",
        title: "Lab Manager",
        start: "August 2021",
        end: "Present",
        description: [
            "Develop curriculum for STEM education for K-PhD students in local community",
            "Select, purchase, and document machinery to advance organization\u2019s mission",
            "Promote interdisciplinary skill development for users with an emphasis on rapid prototyping and design for manufacture",
            "Collaborate with CSCE 606 class to develop custom web-app for reservation"
        ],
        link: "/swirl",
    },
    {
        name: "TA",
        long_name: "Texas A&M Department of Computer Science",
        title: "Graduate (&Undergraduate) Teaching Assistant",
        start: "January 2020",
        end: "December 2021",
        description: [
            "Lab instructor, office hours, proctor exams & guest lecture as needed",
            "Design, develop, and grade assignments",
            "Manage team of 10 TAs over 12 sections and 300 students per semester",
            "Worked on multiple variants of commercial and internally-developed auto-grading solutions",
        ],
        link: "/gradescope",
    },
    {
        name:"AggieRobotics",
        long_name: "Aggie Robotics",
        title: "Vice-President, Advisor",
        start: "August 2018",
        end: "December 2021",   
        description: [
            "Compete in VexU Robotics Competition",
            "Develop low-cost sumo bots program based on ESP32 and Webapp",
            "Integrate Aggie Robotics as founding member of SwIRL",
            "Transition to advisory role as organization matured",
        ],
        link:"/sumobots", 
    },
    {
        name: "FB2",
        long_name: "Facebook",
        title: "Software Development Intern",
        start: "May 2021",
        end: "August 2021",
        description: [
            "Developed and deployed service wrapping new test framework to improve reliability and accessibility",
            "Integrated new tool with existing services and CI/CD pipelines",
            "Onboarding new users onto tool with emphasis on documentation-based ramp up",
        ],
        link:"/facebook",
    },
    {
        name: "HonorsResearch",
        long_name: "Texas A&M Engineering Honors, Computer Science",
        title: "Undergraduate Research Project",
        start: "June 2020",
        end: "May 2021",
        description: [
            "Published Paper: 3D PRINTING PATH REALLOCATION FOR CONCURRENT IDEX SYSTEMS",
            "Texas A&M University Research Scholars (URS) Program",
            "Develop algorithm to convert existing single-agent paths into multi-agent contexts for Dual Extrusion 3D printers",
            "Self-driven development in Python and C++ focused on experiment backed results",
        ],
        link:"/urs",
    },
    {
        name: "FB1",
        long_name: "Facebook",
        title: "Software Development Intern",
        start: "May 2020",
        end: "August 2020",
        description: [
            "Supported Network Analytics team to develop tools for monitoring network traffic health in Facebook data centers",
            "Developed new auditing tool and process to increase anomaly detection timeliness and alert relevant monitoring team",
            "Reworked Widely Distributed Binary library from 30GB to 14GB memory usage through hashing and sharding",
        ],
        link:"/facebook",
    },
    {
        name: "SummerResearch",
        long_name: "Texas A&M Research Experiences for Undergraduates",
        title: "Summer Undergraduate Research Assistant",
        start: "May 2019",
        end: "December 2019",
        description: [
            "3D Printing Continuous Fiber-Reinforced Thermoset using 5-Axis Robot Arm",
            "National Science Foundation's (NSF) REU (Research Experiences for Undergraduates) Site on Cybermanufacturing (Grant #1757882)",
            "Python Solution for Inverse Kinematics and Path planning for Dorna Robotics 5 Axis Arm",
            "Test material properties of resulting composite material",
            "Prepared paper and poster in coordination with Texas A&M\u2019s College of Engineering Undergraduate Summer Research Program.",
        ],
        link:"/reu",
    },
    {
        name: "KidKraft",
        long_name: "KidKraft, Inc.",
        title: "Summer Engineering Intern",
        start: "May 2017, 2018",
        end: "August 2017, 2018",
        description: [
            "Designed parts and assemblies in SolidWorks",
            "Adapted artistic concepts to manufacturable designs",
            "Proposed modifications to pre-production models to optimize production process based upon sample review and comments from factory",
            "Validated production samples, quote packets, and assembly instructions against internal documentation to ensure accuracy",
            "Tested new and existing products for compliance with American Society for Testing and Materials (ASTM) and Consumer Product Safety Commission (CPSC) guidelines",
            "Supported KidKraft\u2019s international offices by answering technical queries",
            "Design injection molded components",
        ],
        link:"/kidkraft",
    },
    {
        name:"FRC",
        long_name: "FIRST Robotics Competition Team 2848",
        title: "President",
        start: "August 2014",
        end: "May 2017",
        description: [
            "Fabrication Lead, Design Team Member",
            "Emphasis on rapid prototyping and design for manufacture",
            "Knee Mill, Gunsmiths Lathe, Laser Cutter, Plasma Cutter, CNC Mill, Sheet Metal",
        ],
        link:"/frc",
    },
];

const ResumePage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Resume</h1>
            <p>Howdy, welcome to my e-resume. This is just the highlights reel; I recommend you also check out the <a href="/current">Current Projects</a> and <a href="/projects">All Projects</a></p>
            <hr></hr>
            <sub>Ordered in reverse order of end date</sub>
            {resume_elements.map(e => (
                ResumeEntry(e)
            ))}
            {FooterBar()}
        </div>
    )
}

export default ResumePage