import * as React from "react"

function ResumeEntryDescr(prop) {
    return (
        <ul>
            {prop.map(e => (
                <li>{e}</li>
            ))
            }
        </ul>
    )
}

function ResumeEntry(prop) {
    return (
        <div class="resume_entry">
            <h4>{prop.title}</h4>
            <p>{prop.long_name}</p>
            <div>{prop.start}-{prop.end}</div>
            {ResumeEntryDescr(prop.description)}
            <a href={prop.link}>Read More</a>
        </div>
    )
}

export default ResumeEntry